import React from 'react';
import { Colors, Fonts } from '../util';
import ActionHistoryStack from '../components/ActionHistoryStack';
import AuthenticityResult from '../components/AuthenticityResult';
import CustomFieldsTable from '../components/CustomFieldsTable';
import Fader from '../components/Fader';
import Paragraph from '../components/Paragraph';
import ProductImage from '../components/ProductImage';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';
import ToggleButton from '../components/ToggleButton';
import WideContainer from '../components/WideContainer';

const HorizontalSpacer = () => {
  const style = {
    height: '2px',
    minWidth: '100px',
    backgroundColor: Colors.lightGrey,
    margin: '30px auto',
    maxWidth: '300px',
  };

  return <div style={style}/>;
};

const AlyxProductInfo = ({ product }) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
  };
  const itemStyle = {
    color: Colors.darkGrey,
    fontFamily: Fonts.body,
    fontSize: '1.0rem',
    maxWidth: '90%',
    margin: '5px 19px',
    textAlign: 'left',
  };

  return (
    <div style={containerStyle}>
      <p style={itemStyle}>{product.name.toUpperCase()}</p>
      <p style={itemStyle}>{product.description.toUpperCase()}</p>
      <p style={itemStyle}>COLOR: {(product.customFields.color || 'Unknown').toUpperCase()}</p>
      <p style={itemStyle}>SIZE: {(product.customFields.size || 'Unknown').toUpperCase()}</p>
    </div>
  );
};

class ResultsPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      historyShown: false,
    };
  }

  render() {
    const { product, isAuthentic } = this.props.state;

    return (
      <Fader>
        <WideContainer>
          <ProductImage product={product}/>
          <AlyxProductInfo product={product}/>

          {isAuthentic !== null && (
            <Fader>
              <AuthenticityResult state={this.props.state}/>
            </Fader>
          )}
          {isAuthentic === null && <Paragraph>Loading from blockchain...</Paragraph>}

          {isAuthentic === true && (
            <Fader>
              <ToggleButton enabledText="HIDE JOURNEY" disabledText="SHOW JOURNEY" 
                onToggle={historyShown => this.setState({ historyShown })}/>
            </Fader>
          )}
          {this.state.historyShown === true && (
            <Fader>
              <Subtitle>Product Journey</Subtitle>
              <Paragraph restyle={{ textAlign: 'left' }}>
                The timeline below shows the detailed journey of the product from factory to your 
                hands.
              </Paragraph>
              <ActionHistoryStack state={this.props.state}/>
            </Fader>
          )}
        </WideContainer>
      </Fader>
    );
  }

}

export default ResultsPage;
