import React from 'react';
import { buttonStyle } from '../util';

class ToggleButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = { enabled: false };
  }

  toggle() {
    this.props.onToggle(!this.state.enabled);
    this.setState({ enabled: !this.state.enabled });
  }

  render() {
    const style = Object.assign({}, buttonStyle, {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid black',
      width: '340px',
    });

    return (
      <div style={style} onClick={this.toggle.bind(this)}>
        {this.state.enabled ? this.props.enabledText : this.props.disabledText}
      </div>
    );
  }

}

export default ToggleButton;
