import React from 'react';
import { Colors, Fonts } from '../util';

const Paragraph = ({ children, restyle }) => {
  const style = Object.assign({
    color: Colors.darkGrey,
    fontFamily: Fonts.body,
    fontSize: '1.1rem',
    fontStyle: 'initial',
    margin: '10px 19px 10px',
    textAlign: 'center',
  }, restyle);

  return <p style={style}>{children}</p>;
};

export default Paragraph;
