import { COMMISSION_ACTION_TYPE } from './commissionActionType';

export const Events = {
  '': {
    idMethod: 'none',
    fields: [],
  },
  _RawMaterialsSupplied: {
    friendlyName: 'Raw Materials Supplied',
    idMethod: 'rmln',
    identifier: 'rawMaterialLotNumber',
    icon: 'factory.png',
    fields: [
      'productionDate',
      'compositionDetails',
      'quantity',
      'rawMaterialCode',
      'shippingDate',
      'shipmentTrackingNumber',
      'destination',
      'orderNumber',
    ],
  },
  _RawMaterialsReceived: {
    friendlyName: 'Raw Materials Received',
    idMethod: 'rmln',
    identifier: 'rawMaterialLotNumber',
    icon: 'delivered.png',
    fields: [
      'receivingDate',
      'orderNumber',
      'rawMaterialCode',
    ],
  },
  _LabelsProduced: {
    friendlyName: 'Labels Produced',
    idMethod: 'po',
    identifier: 'purchaseOrder',
    icon: 'label.png',
    fields: [
      'rawMaterialLotNumber',
      'itemCode',
      'productionDate',
      'quantity',
      'tapeCode',
      'inkCode',
    ],
  },
  _LabelsShipped: {
    friendlyName: 'Labels Shipped',
    idMethod: 'po',
    identifier: 'purchaseOrder',
    icon: 'truck.png',
    fields: [
      'itemCode',
      'trackingNumber',
      'shippingDate',
      'shippingWeight',
      'destination',
    ],
  },
  _LabelsReceived: {
    friendlyName: 'Labels Received',
    idMethod: 'po',
    identifier: 'purchaseOrder',
    icon: 'delivered.png',
    fields: [
      'itemCode',
      'receivingDate',
      'quantity',
    ],
  },
  _GarmentsProduced: {
    friendlyName: 'Garments Produced',
    idMethod: 'pro',
    identifier: 'productionOrder',
    icon: 'factory.png',
    fields: [
      'description',
      'itemDetails',
      'quantity',
      'productionDate',
      'productionLocation',
      'rawMaterialComposition',
      'MainRawMaterial1',
      'MainRawMaterial2',
    ],
  },
  [COMMISSION_ACTION_TYPE]: {
    friendlyName: 'Label Applied',
    idMethod: 'scan',
    identifier: '',
    icon: '',
    fields: [
      'description',
      'itemDetails',
      'product',
      'productionOrder',
      'activationDate',
      'Customer PO',
      'quantity',
    ],
  },
  _Commissioned: {
    icon: 'label.png',
  },
  _FinishedGoodsStored: {
    friendlyName: 'Finished Goods Stored',
    idMethod: 'pro',
    identifier: 'productionOrder',
    icon: 'delivered.png',
    fields: [
      'itemDetails',
      'date',
    ],
  },
  _FinishedGoodsSold: {
    friendlyName: 'Finished Goods Sold',
    idMethod: 'scan',
    identifier: '',
    icon: 'cart.png',
    fields: [
      'itemDetails',
      'date',
      'orderId',
    ],
  }
};

export const Places = {
  'Avery Dennison Italy': {
    id: 'UqfwY2FfBbtHErRwRmEP6aMk',
    events: [
      '_RawMaterialsSupplied',
    ],
  },
  'Avery Dennison Spain': {
    id: 'U6fah2Ea4gA7egwawGmyBnPq',
    events: [
      '_RawMaterialsReceived',
      '_LabelsProduced',
      '_LabelsShipped',
    ],
  },
  'ALYX Manufacturing': {
    id: 'U6KYAXEhSBE2qxawwGwYcqQe',
    events: [
      '_GarmentsProduced',
      COMMISSION_ACTION_TYPE,
    ],
  },
  'ALYX Warehouse': {
    id: 'Uq5XtwCKggapAkRaa3fFhtHn',
    events: [
      '_LabelsReceived',
      '_FinishedGoodsStored',
      '_FinishedGoodsSold',
    ],
  },
};

export const Units = {
  dimensions: 'M x mm',
  quantity: 'units',
  shippingWeight: 'Kg',
};

export const ItemDetails = [
  'MOTO CROSS PANTAAWPA0024A001BLACK38',
  'MOTO CROSS PANTAAWPA0024A001BLACK42',
  'MOTO CROSS PANTAAWPA0024A001BLACK44',
  'LOGO COLLECTION HOODIEAVUSW0001A001BLACKS',
  'LOGO COLLECTION HOODIEAVUSW0001A001BLACKM',
  'LOGO COLLECTION HOODIEAVUSW0001A001BLACKL',
  'LOGO COLLECTION HOODIEAVUSW0001A001BLACKXL',
  'LOGO COLLECTION HOODIEAVUSW0001A001BLACKXXL',
  'LOGO COLLECTION HOODIEAVUSW0001A001BLACKXS',
  'COLLAGE SS TEEAVUTS0003A001BLACKS',
  'COLLAGE SS TEEAVUTS0003A001BLACKM',
  'COLLAGE SS TEEAVUTS0003A001BLACKL',
  'COLLAGE SS TEEAVUTS0003A001BLACKXL',
  'CAMPING BACKPACK W/RAIN COVERAAUBA0001B001BLACKU',
  'COLLECTION CODE SS TEEAVUTS0007A007WHITEXXS',
  'COLLECTION CODE SS TEEAVUTS0007A007WHITEXS',
  'COLLECTION CODE SS TEEAVUTS0007A007WHITES',
  'COLLECTION CODE SS TEEAVUTS0007A007WHITEM',
  'COLLECTION CODE SS TEEAVUTS0007A007WHITEL',
  'COLLECTION CODE SS TEEAVUTS0007A007WHITEXL',
  'MULTIPOCKET BLAZERAAWTA0015A001BLACK36',
  'MULTIPOCKET BLAZERAAWTA0015A001BLACK38',
  'MULTIPOCKET BLAZERAAWTA0015A001BLACK40',
  'MULTIPOCKET BLAZERAAWTA0015A001BLACK42',
  'MULTIPOCKET BLAZERAAWTA0015A001BLACK44',
  'MULTIPOCKET BLAZERAAWTA0015A001BLACK46',
  'CLASSIC CHEST RIG W/RAIN COVERAAUCB0008A001BLACKU',
  'COLLAGE SS TEEAVUTS0003A001BLACKXXS',
  'COLLAGE SS TEEAVUTS0003A001BLACKXS',
  'COLLAGE SS TEEAVUTS0003A001BLACKXXL',
  'COLLECTION CODE SS TEEAVUTS0007A007WHITEXXL',
  'CLASSIC SUIT PANTAAMPA0018A001BLACK44',
  'CLASSIC SUIT PANTAAMPA0018A001BLACK46',
  'CLASSIC SUIT PANTAAMPA0018A001BLACK48',
  'CLASSIC SUIT PANTAAMPA0018A001BLACK50',
  'CLASSIC SUIT PANTAAMPA0018A001BLACK52',
  'CLASSIC SUIT PANTAAMPA0018A001BLACK54',
  'CLASSIC BLAZERAAMTA0008A001BLACK44',
  'CLASSIC BLAZERAAMTA0008A001BLACK46',
  'CLASSIC BLAZERAAMTA0008A001BLACK48',
  'CLASSIC BLAZERAAMTA0008A001BLACK50',
  'CLASSIC BLAZERAAMTA0008A001BLACK52',
  'CLASSIC BLAZERAAMTA0008A001BLACK54',
];
