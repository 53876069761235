import React from 'react';
import LogosFooter from '../components/LogosFooter';
import Button from '../components/Button';
import Fader from '../components/Fader';
import PageIcon from '../components/PageIcon';
import Paragraph from '../components/Paragraph';
import Title from '../components/Title';
import WideContainer from '../components/WideContainer';

const FooterSpacer = () => {
  const style = {
    width: '100%',
    height: '35px',
  };

  return <div style={style}/>;
};

const LandingPage = ({ appCallbacks }) => {
  const { startScan } = appCallbacks;

  return (
    <Fader>
      <WideContainer>
        <PageIcon src="../../assets/check-auth.png"/>
        <Title>Product Information</Title>
        <Paragraph>
          We've used blockchain-based technology to ensure that the information you see about your 
          product can be trusted as authentic since the moment it was produced.
        </Paragraph>
        <Paragraph>
          Scan your purchase to check its authenticity, and view its journey into your hands.
        </Paragraph>
        <Button onClick={startScan}>Scan Item</Button>
        <FooterSpacer/>
        <LogosFooter/>
      </WideContainer>
    </Fader>
  );
};

export default LandingPage;
