const hashJs = require('hash.js');
const jsonSortify = require('json.sortify');
const { getThngIotaData, getCollectionsIotaData } = require('./iota');

import { COMMISSION_ACTION_TYPE } from '../../traceability-app/src/commissionActionType';

export const FilterActionTypes = {
  sentToIOTA: '_sentToIOTA',
  originTrailCertified: '_originTrailCertified',
};
const OT_NODE_URL = 'https://origintrail.evrythng.io';

export const SKIP_IOTA = false;

export const Colors = {
  black: 'black',
  white: 'white',
  darkGrey: '#434343',
  veryDarkGrey: '#1f1f1f',
  lightGrey: '#0005',
  veryLightGrey: '#7771',
  authenticText: 'rgb(64, 163, 63)',
  authenticBackground: '#DDFFDD',
  notAuthenticText: 'rgb(244, 57, 54)',
  notAuthenticBackground: '#FFDDDD',
  secondary: '#CCC',
  lightGreen: 'rgb(198, 217, 56)',
};

export const Fonts = {
  title: 'AFT-001-R, sans-serif',
  body: 'AFT-001-R, sans-serif',
};

export const buttonStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: Colors.white,
  backgroundColor: Colors.black,
  height: '45px',
  width: '180px',
  fontFamily: Fonts.body,
  fontSize: '1.1rem',
  margin: '15px auto 10px',
  textAlign: 'center',
  cursor: 'pointer',
};

export const whiteButtonStyle = {
  color: Colors.black,
  backgroundColor: Colors.white,
  border: `1px solid ${Colors.lightGrey}`,
};

/**
 * Build a localStorage key for the app user ID in this application.
 *
 * @param {object} appScope - The App scope.
 * @returns {string} The built localStorage key.
 */
const buildAppUserIdKey = appScope => `auth_app_user_id_${appScope.id}`;

/**
 * Build a localStorage key for the app user key in this application.
 *
 * @param {object} appScope - The App scope.
 * @returns {string} The built localStorage key.
 */
const buildAppUserKeyKey = appScope => `auth_app_user_key_${appScope.id}`;

/**
 * Create a new anonymous Application User and save the credentials in localStorage.
 *
 * @param {object} appScope - The App scope.
 * @returns {object} The created anonymous Application User.
 */
const createNewAnonUser = appScope => appScope.appUser()
  .create({ anonymous: true })
  .then((appUser) => {
    localStorage.setItem(buildAppUserIdKey(appScope), appUser.id);
    localStorage.setItem(buildAppUserKeyKey(appScope), appUser.apiKey);
    console.log('Created new app user');
    return appUser;
  });

/**
 * Get an anonymous Application User to use in the app.
 *
 * @param {object} appScope - The App scope.
 * @returns {object} The created anonymous Application User.
 */
export const getAnonUser = (appScope) => {
  const id = localStorage.getItem(buildAppUserIdKey(appScope));
  const apiKey = localStorage.getItem(buildAppUserKeyKey(appScope));
  if (!id || !apiKey) {
    return createNewAnonUser(appScope);
  }

  // Load existing user (problems changing project...)
  const appUser = new EVT.User({ id, apiKey });
  return appUser.$init
    .then(() => appUser)
    .catch(err => createNewAnonUser(appScope));
};

/**
 * Hash an action using deterministic sorting, then SHA256 hashing.
 *
 * @param {object} action - The action to hash.
 * @returns {string} Hash of the action.
 */
const getSHA256 = action => hashJs.sha256()
  .update(jsonSortify(action))
  .digest('hex');

/**
 * Determine if the item is in the correct state, i.e: has completed the journey.
 *
 * @param {object} state - The app state.
 * @returns {boolean} Whether or not the item is in the correct state.
 */
const itemInCorrectState = (state) => {
  const requiredConfirmedTypes = [
    '_RawMaterialsSupplied', '_RawMaterialsReceived', '_LabelsProduced', '_LabelsShipped', 
    '_LabelsReceived', '_GarmentsProduced', '_Commissioned', '_FinishedGoodsStored',
  ];

  const journeyComplete = requiredConfirmedTypes.every((type) => {
    if (!state.actionHistory.find(p => p.type === type)) {
      console.log(`Missing action type: ${type}`);
      return false;
    }

    return true;
  });
  if (!journeyComplete) {
    console.log('Some traceability events are missing');
    return false;
  }

  return true;
};

/**
 * Check hash of each action with sendToIOTA customField with those retrieved
 * using the Thng's recorded root address (+ also Thng collections)
 *
 * @param {object} state - The App state.
 * @returns {Promise} Promise that resolves the result of checking each action.
 */
export const verifyConfirmations = (state, setState) => {
  const { thng, collections, actionHistory } = state;
  let isAuthentic = false;
  let notAuthenticReason = '';

  // No data on Thng
  if (!thng.customFields || !thng.customFields.iotaRoot || !actionHistory.length) {
    notAuthenticReason = 'No event data found';
    console.log(`Thng iotaRoot and/or action history not found`);
    return Promise.resolve({ isAuthentic, notAuthenticReason });
  }

  // Or no data on collections
  if (!collections.every(p => p.customFields && p.customFields.iotaRoot)) {
    notAuthenticReason = 'No event data found';
    console.log(`Thng collections do not have iotaRoot`);
    return Promise.resolve({ isAuthentic, notAuthenticReason }); 
  }

  // We only need context.city for comparisons
  actionHistory.forEach((action) => {
    action.context = { city: action.context.city };
  });

  // Check item is in the correct state for consumers
  if (!itemInCorrectState(state)) {
    return { isAuthentic, notAuthenticReason: 'Item\'s journey is incomplete' };
  }

  // IOTA can be unreliable, so allow a weaker version of authenticity that checks events
  if (SKIP_IOTA) {
    return { isAuthentic: true, notAuthenticReason };
  }

  // Read all transactions for the Thng and all of its collections
  return Promise
    .all([
      getThngIotaData(thng),
      getCollectionsIotaData(collections),
    ])
    .then(res => res[0].concat(res[1]))
    .then(async (txHashes) => {
      const confirmations = actionHistory.filter(p => p.type === FilterActionTypes.sentToIOTA);
      const computed = confirmations.map(p => getSHA256(p.customFields.originalAction));
      console.log(`IOTA hashes: ${JSON.stringify(txHashes, null, 2)}`);
      console.log(`Action hashes: ${JSON.stringify(computed, null, 2)}`);

      // Mark each action as verified
      confirmations.forEach((item) => {
        item.isVerified = txHashes.includes(getSHA256(item.customFields.originalAction));
      });

      // Every hash in the blockchain must appear in the list of computed action hashes
      const txHaveAction = txHashes.every(tx => computed.includes(tx));
      if (!txHaveAction) {
        return { isAuthentic, notAuthenticReason: 'Some events could not be verified' };
      }

      // Every originalAction computer must appear in the blockchain hashes
      const actionsHaveTx = computed.every(cmp => txHashes.includes(cmp));
      if (!actionsHaveTx) {
        return {
          isAuthentic,
          notAuthenticReason: 'Some Blockchain transactions could not be verified',
        };
      }

      isAuthentic = (confirmations.length && txHaveAction && actionsHaveTx);
      console.log(`Authentic: ${isAuthentic}`);
      return { isAuthentic, notAuthenticReason };
    });
};

/**
 * Return an icon path depending on a boolean given.
 *
 * @param {boolean} isAuthentic - The authenticity state.
 * @returns {string} Path to the resulting image file.
 */
export const getResultIcon = isAuthentic => `../../assets/${isAuthentic ? 'check-lightgreen' : 'cross'}.png`;

/**
 * Format a string to be more user-friendly.
 * - Removes underscore
 * - Inserts space before capital letters that start words
 * - Capitalises first letter.
 *
 * @param {string} type - The string, such as _itemShipped.
 * @returns {string} - Cleaned string, such as 'Item Shipped'.
 */
export const formatCamelCase = (type) => {
  const replaced = type.replace(/_/g, '')
    .replace(/([A-Z]{1}[a-z]{1,}|[A-Z]{2,})/g, ' $1')
    .trim();
  const first = replaced.charAt(0);
  return `${first.toUpperCase()}${replaced.substring(1)}`;
};

/**
 * Get a specific query parameter value, if it exists in the query string.
 *
 * @param {string} name - Name of the parameter.
 * @returns {string} The value of the parameter, if it exists.
 */
export const getQueryParam = name => window.location.search.slice(1)
  .split('&')
  .reduce((result, item) => {
    const [key, value] = item.split('=');
    result[key] = value;
    return result;
  }, {})[name];

/**
 * Unpack the action history using the originalAction stored in each confirmation action.
 *
 * @param {Array} actions - The read confirmation actions
 * @returns {Array} The unpacked history, sorted by timestamp.
 */
export const unpackHistory = actions => actions
  .filter(p => p.type === FilterActionTypes.sentToIOTA)
  .reduce((res, confirmation) => {
    res.push(confirmation);
    res.push(confirmation.customFields.originalAction);
    return res;
  }, [])
  .sort((a, b) => a.timestamp > b.timestamp);

/**
 * Handle missing context when building location string.
 *
 * @param {object} context - The context to use.
 * @returns {string} The location string.
 */
export const getLocationString = (context) => {
  if (!context) {
    return 'Unknown';
  }

  let result = '';
  if (context.city) {
    result += context.city;
  }
  if (context.region) {
    result += `, ${context.region}`;
  }
  if (context.countryCode) {
    result += ` (${context.countryCode})`;
  }

  return result;
};

/**
 * Show a more user-friendly name for some action types.
 *
 * @param {string} name - The action type name.
 * @returns {string} The replaced name, or the original if no replacement specified.
 */
export const friendlyName = (name) => {
  const replacerMap = {
    '_sentToIOTA': 'Blockchain Confirmation',
  };

  return replacerMap[name] ? replacerMap[name] : name;
};

/**
 * Determine the timestamp for an event according to simple rules.
 *
 * Order of precedence:
 *   shippingDate > productionDate > activationDate > receivingDate > timestamp
 *
 * @param {object} event - The event to examine.
 * @returns {number} The milliseconds timestamp of the most relevent date.
 */
export const getTimestamp = (event) => {
  const { shippingDate, productionDate, activationDate, receivingDate, date } = event.eventData;
  if (shippingDate) {
    return new Date(shippingDate).valueOf();
  }
  if (productionDate) {
    return new Date(productionDate).valueOf();
  }
  if (activationDate) {
    return new Date(activationDate).valueOf();
  }
  if (receivingDate) {
    return new Date(receivingDate).valueOf();
  }
  if (date) {
    return new Date(date).valueOf();
  }
  return event.timestamp;
};

/**
 * Fallback sorter if the dates are the same.
 *
 * @param {object} a - Event A.
 * @param {object} b - Event B.
 * @returns {number} -1 if A should appear before B (nearer the top), else 1.
 */
const sortByEventOrder = (a, b) => {
  const orderMap = {
    _RawMaterialsSupplied: 0,
    _RawMaterialsReceived: 1,
    _LabelsProduced: 2,
    _LabelsShipped: 3,
    _LabelsReceived: 4,
    _GarmentsProduced: 5,
    [COMMISSION_ACTION_TYPE]: 6,
    _FinishedGoodsStored: 7,
    _FinishedGoodsSold: 8,
  };

  console.log(`Fallback sorted ${a.type} against ${b.type}`);
  return orderMap[a.type] > orderMap[b.type] ? -1 : 1;
};

/**
 * Sort the events by a heirarchical set of dates.
 * If the dates are the same, fall back to the 'logical' order
 *
 * @param {object} a - Event A.
 * @param {object} b - Event B.
 * @returns {number} -1 if A should appear before B (nearer the top), else 1.
 */
const sortByDate = (a, b) => {
  const tsA = getTimestamp(a);
  const tsB = getTimestamp(b);

  return tsA === tsB
    ? sortByEventOrder(a, b)
    : tsA > tsB ? -1 : 1;
};

/**
 * Special cases.
 * If there are more than one of each type of action, pick the OLDEST
 * If the type is '_LabelsProduced', pick the NEWEST
 *
 * @param {object} event - The event in question.
 * @param {number} eventIndex - Index of this event.
 * @param {object[]} events - The events to filter.
 * @returns {object[]} Array of events with 'correct' duplicates selected.
 */
const filterDuplicateEvents = (event, eventIndex, events) => {
  const instances = events.filter(p => p.type === event.type);

  // If there's just one, take it
  if (instances.length === 1) {
    return true;
  }

  // Else, choose the newest for '_LabelsProduced'
  if (event.type === '_LabelsProduced') {
    const chooseNewest = (res, item) => (getTimestamp(res) < getTimestamp(item)) ? res : item;
    return event === instances.reduce(chooseNewest, instances[0]);
  }

  // Else, accept the oldest for all other types
  const chooseOldest = (res, item) => (getTimestamp(res) > getTimestamp(item)) ? res : item;
  return event === instances.reduce(chooseOldest, instances[0]);
};

/**
 * Build array of objects with all properties required for display in one place
 * instead of linking actions together at random times.
 *
 * Also include the _Commissioned confirmation
 *
 * @param {object} state - The app state
 * @returns {Array} Array of event history items with direct properties as required.
 */
export const buildEventHistory = state => state.actionHistory
  .filter(p => p.type === FilterActionTypes.sentToIOTA)
  .reduce((res, item) => {
    const { originalAction } = item.customFields;
    const otUrlItem = state.originTrailActions.find(p => p.customFields.actionId === originalAction.id);
    if (!otUrlItem) {
      console.log(`No OriginTrail confirmation found for ${item.id}`);
    }

    const originTrailUrl = otUrlItem ? otUrlItem.customFields.originTrailUrl : '';

    return res.concat({
      id: originalAction.id,
      type: originalAction.type,
      timestamp: originalAction.timestamp,
      // firstName: originalAction.customFields.firstName,
      context: originalAction.context,
      isVerified: item.isVerified,
      iotaRoot: item.customFields.iotaRoot,
      eventData: originalAction.customFields || {},
      originTrailUrl,
    });
  }, [])
  .filter(filterDuplicateEvents)
  .sort(sortByDate);
