import React from 'react';

const ProductImage = ({ product }) => {
  const style = {
    maxHeight: '200px',
    width: 'auto',
    marginBottom: '10px',
  };
  const { photos } = product;
  const src = (photos && photos.length > 0 && photos[0].length) ? photos[0] : '../../assets/no-photo.png';

  return <img style={style} src={src}/>;
};

export default ProductImage;
