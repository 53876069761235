import React from 'react';

const Container = ({ children }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    width: '100%',
  };

  return <div style={style}>{children}</div>;
};

const Image = ({ src, height }) => {
  const style = {
    display: 'block',
    margin: 'auto',
    marginBottom: '15px',
    height,
  };

  return <img style={style} src={src}/>;
};

const LogosFooter = () => {
  return (
    <Container>
      <Image src="../assets/avery-logo.png" height="95px"/>
      <Image src="../assets/evtlogo.png" height="95px"/>
    </Container>
  );
};

export default LogosFooter;
