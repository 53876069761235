import React from 'react';
import ReactDOM from 'react-dom';
import {
  FilterActionTypes, getAnonUser, verifyConfirmations, getQueryParam, unpackHistory,
  buildEventHistory,
} from './util';
import { NavBar, NavBarSpacer } from './components/NavBar';
import LogosFooter from './components/LogosFooter';
import LandingPage from './pages/LandingPage';
import LoadingPage from '../../traceability-app/src/pages/LoadingPage';
import ResultsPage from './pages/ResultsPage';
import ScanThngPage from './pages/ScanThngPage';

const RootContainer = ({ children }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
  };

  return <div style={style}>{children}</div>;
};

const BlankPage = () => <div/>;

class Application extends React.Component {

  /**
   * @constructor
   *
   * @param {object} props - The properties passed from the parent scope.
   */
  constructor(props) {
    super(props);

    this.state = {
      actionHistory: [],
      appScope: null,
      appUserScope: null,
      collections: null,
      currentPage: BlankPage,
      eventHistory: [],
      isAuthentic: null,
      notAuthenticReason: '',
      originTrailActions: [],
      product: null,
      thng: null,
    };

    this.setStateSync = this.setStateSync.bind(this);
    this.onThngScanned = this.onThngScanned.bind(this);
    this.onScanFail = this.onScanFail.bind(this);
    this.startScan = this.startScan.bind(this);
    this.onShortIdNoThng = this.onShortIdNoThng.bind(this);
  }

  /**
   * When the component is mounted.
   */
  componentDidMount() {
    const appScope = new EVT.App(window.config.APPLICATION_API_KEY);
    getAnonUser(appScope).then((appUserScope) => {
      this.setState({ appScope, appUserScope });

      // Is Thng already specified?
      if (getQueryParam('thng')) {
        this.onThngScanned(getQueryParam('thng'));
        return;
      }

      this.setState({ currentPage: LandingPage });
    });
  }

  /**
   * Set state in a Promise, so that the changes are committed in the next in
   * the chain.
   *
   * @param {object} diff - The changes.
   * @returns {Promise} Promise that resolves once the changes are committed.
   */
  setStateSync(diff) {
    return new Promise(resolve => this.setState(diff, resolve));
  }

  /**
   * Read confirmation actions on the Thng scanned.
   *
   * @param {string} thngId - The Thng ID.
   * @returns {Promise} Promise that resolves when the process is complete.
   */
  readThngConfirmations(thngId) {
    return this.state.appUserScope.thng(thngId)
      .action(FilterActionTypes.sentToIOTA)
      .read({ params: { perPage: 100 } });
  }

  /**
   * Read confirmation actions on the Thng's collections scanned.
   * There can be N collections, which are all reduced as the final step.
   *
   * @returns {Promise} Promise that resolves when the process is complete.
   */
  readThngCollectionConfirmations() {
    return Promise.all(this.state.collections
      .map(p => this.state.appUserScope.collection(p.id)
        .action(FilterActionTypes.sentToIOTA)
        .read({ params: { perPage: 100 } })))
      .then(res => res.reduce((final, item) => final.concat(item)), []);
  }

  /**
   * Read the Thng's & Thng collections' action history, then judge its authenticity.
   * Only confirmation actions are read - they contain the original actions too
   *
   * @param {string} thngId - The Thng ID.
   * @returns {Promise} Promise that resolves when the process is complete.
   */
  readConfirmationHistory(thngId) {
    return Promise.all([
      this.readThngConfirmations(thngId),
      this.readThngCollectionConfirmations(),
    ]).then(res => res[0].concat(res[1]))
      .then(unpackHistory)
      .then(actionHistory => this.setStateSync({ actionHistory }));
  }

  /**
   * Read a resource's 'Origin Trail' - its actions of type _originTrailCertified.
   *
   * @param {string} id - The resource's ID.
   * @param {string} targetType - The resource's type, either 'thng' or 'collection'.
   * @returns {Promise} Promise that resolves a list of actions.
   */
  readOriginTrail(id, targetType) {
    return this.state.appUserScope[targetType](id)
      .action(FilterActionTypes.originTrailCertified)
      .read({ params: { perPage: 100 } });
  }

  /**
   * Read all the originTrailCertified actions for this Thng. 
   *
   * @param {string} thngId - The Thng ID.
   * @returns {Promise} Promise that resolves when the process is complete.
   */
  readOriginTrailActions(thngId) {
    const promises = [this.readOriginTrail(thngId, 'thng')]
      .concat(this.state.collections.map(p => this.readOriginTrail(p.id, 'collection')));
    return Promise.all(promises)
      .then(res => res.reduce((result, p) => result.concat(p)), [])
      .then(originTrailActions => this.setState({ originTrailActions }));
  }

  /**
   * Read the Thng, then read the full associated product resource.
   *
   * @param {string} thngId - The Thng ID.
   * @returns {Promise} Promise that resolves when the process is complete.
   */
  readThngAndProduct(thngId) {
    const { appUserScope, appScope } = this.state;

    return appUserScope.thng(thngId).read()
      .then(thng => this.setStateSync({ thng }))
      .then(() => appScope.product(this.state.thng.product).read()
      .then(product => this.setState({ product })));
  }

  /**
   * Read the Thng's collections.
   *
   * @returns {Promise} Promise that resolves when the process is complete.
   */
  readThngCollections() {
    const { collections } = this.state.thng;
    if (!collections) {
      // Thng has no collections - some step was skipped!
      return this.setStateSync({ currentPage: LandingPage })
        .then(() => {
          throw new Error('Item did not belong to any collections.');
        });
    }

    const promises = collections.map(p => this.state.appUserScope.collection(p).read());
    return Promise.all(promises).then(collections => this.setStateSync({ collections }));
  }

  /**
   * Create a 'scans' action to signify a completed scan.
   * @param {string} thngId - The Thng ID result of the scan request from the API.
   */
  createScanAction(thngId) {
    return this.state.appUserScope.action('scans')
      .create({ type: 'scans', thng: thngId });
  }

  /**
   * When the Scanner component has scanned a QR code.
   *
   * @param {string} thngId - The Thng ID result of the scan request from the API.
   */
  onThngScanned(thngId) {
    this.setStateSync({ currentPage: LoadingPage })
      .then(() => this.readThngAndProduct(thngId))
      .then(() => this.readThngCollections())
      .then(() => this.setState({ currentPage: ResultsPage }))
      .then(() => this.readConfirmationHistory(thngId))
      .then(() => verifyConfirmations(this.state))
      .then(newStateDiff => this.setStateSync(newStateDiff)) // { isAuthentic, notAuthenticReason }
      .then(() => this.readOriginTrailActions(thngId))
      .then(() => buildEventHistory(this.state))
      .then(eventHistory => this.setState({ eventHistory }))
      // .then(() => this.createScanAction(thngId))
      .catch((err) => {
        console.log(err);
        alert(err.message || err.errors[0]);
        this.setState({ currentPage: LandingPage });
      });
  }

  /** 
   * When a scan fails to resolve a recognised Thng
   */
  onScanFail() {
    this.setState({ currentPage: LandingPage }); 
  }

  /**
   * No Thng was found, but a value was decoded.
   *
   * @param {string} value - The decoded value.
   */
  onShortIdNoThng(value) {
    console.log(`Thng not found, but found value ${value}`);
    alert('Item not found!');

    this.setState({ currentPage: LandingPage });
  }

  /**
   * Reset app state for another scan.
   */
  resetState() {
    this.setState({ 
      actionHistory: [],
      collections: null,
      currentPage: LandingPage,
      eventHistory: [],
      isAuthentic: null,
      notAuthenticReason: '',
      originTrailActions: [],
      product: null,
      thng: null,
    });
  }

  /**
   * Start scan process.
   */
  startScan() {
    this.setState({ currentPage: ScanThngPage });
  }

  /**
   * What the component will render.
   */
  render() {
    const CurrentPage = this.state.currentPage;
    const appCallbacks = {
      onThngScanned: this.onThngScanned,
      onScanFail: this.onScanFail,
      startScan: this.startScan,
      onShortIdNoThng: this.onShortIdNoThng,
    };
    
    return (
      <RootContainer>
        <NavBar logoSrc="../assets/logo.png" showBack={this.state.thng !== null} 
          backSrc="../assets/back-black.png"
          backOnClick={this.resetState.bind(this)}/>
        <NavBarSpacer/>
        <CurrentPage state={this.state} setState={this.setState.bind(this)}
          appCallbacks={appCallbacks}/>
        {this.state.eventHistory.length > 0 && <LogosFooter/>}
      </RootContainer>
    );
  }

}

ReactDOM.render(<Application/>, document.getElementById('app'));
